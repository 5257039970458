export const languageOptions = [
    { label: 'English', value: 'English' },
    { label: 'Arabic', value: 'Arabic' },
    { label: 'Yoruba', value: 'Yoruba' },
    // { label: 'Hausa', value: 'Hausa' },
    // { label: 'Igbo', value: 'Igbo' },
]
export const categoryOptions = [
    { label: 'Nikaah', value: 'Nikaah' },
    { label: 'Child Upbringing', value: 'Child Upbringing' },
    { label: 'Marital Home', value: 'Marital Home' },
]

export const tags = {
    Nikaah: 'Nikaah',
    Home: 'Marital Home',
    Child: 'Child Upbringing',
}

export const tagOptions = ['Nikaah', 'Marital Home', 'Child Upbringing']

export const langOptions = ['Yoruba', 'English', 'Arabic']

export const artists = {
    dr_sharaf: 'Dr Sharafudeen Raji Gbadebo',
    prof_alaro: 'Shaykh Prof Abdur Razaaq Alaro',
    amau: 'Al Madrasatu Al Umariyyah',
    abu_taymiyyah: 'Ustadh Abu Taymiyyah',
    dr_luqmaan: 'Dr Luqmaan Idris Sekoni',
    saajid: 'Saajid Lipham',
    abu_usaamah: 'Sheikh Abu Usamah At-Thahabi',
    shaykh_ruhaylee: 'Shaykh Sulayman ar-Ruhayli',
    shaykh_abdsalam: "Shaykh Abdussalam al-Shuway'ir",
    ustaadh_hassaan: 'Ustadh AbdulRahman Hassan',
    ustaadh_ismael_ibnraji: "Ustadh Isma'eel Ibn Muhd Raji",
    ustadh_rageah: 'Sheikh Said Rageah',
    onepath: 'OnePath Network',
    tim_humble: 'Ustadh Muhammad Tim Humble',
    shaykh_amubieya: 'Shaykh Sulaymaan Amubieya',
    ustaadh_rasheedhasheem: 'Ustaadh Rasheed Hasheem',
    shaykh_bin_uthmaan: 'Shaykh Muhammad bin Uthmaan',
    shaykh_ajia: 'Shaykh AbdulWahhaab Ajia',
    ustaadh_saeedhamzah: "Ustaadh Sa'eed Hamzah",
    ustaadh_eleyinke: "Ustadh Abdul Waasi' Eleyinke (Yoruba)",
}

export const plyr_config = 'plyr_config'
export const plyr_timelogs = 'plyr_timelogs'
export const plyr_lastplayed = 'plyr_lastplayed'
export const plyr_alert_dismissed = 'plyr_alert_dismissed'
