var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',{attrs:{"position":"relative","maxW":"600px","w":{ base: '100%', md: '49%' },"minH":"140px","borderColor":_vm.mode('gray.300', 'gray.700'),"bg":_vm.mode('white', '#011627'),"borderRadius":"4px","my":"3","border":"0.5px solid rgba(0, 0, 0, 0.35)"}},[_c('CBox',{attrs:{"w":"100%","position":"absolute","left":"0","bottom":"0"}},[_c('ProgressBar',{attrs:{"percentage":this.watchTime}})],1),_c('CFlex',{attrs:{"h":"100%","alignItems":"center","justifyContent":"space-between","gap":"20px","px":"3","py":"5","boxShadow":"sm"}},[_c('CBox',{attrs:{"borderRadius":"50","mx":"auto","cursor":"pointer"},on:{"click":_vm.onPlay}},[_c('CBox',[_c('img',{attrs:{"src":_vm.icon}})])],1),_c('CFlex',{attrs:{"h":"100%","w":"100%","flexDirection":"column","justifyContent":"space-between"}},[_c('CBox',[_c('CText',{attrs:{"fontSize":{
                        base: '.8rem',
                        lg: '1rem',
                    },"fontWeight":"600","textWrap":"wrap","lineHeight":"1.2","mt":"0","mb":"1","color":_vm.mode('inherit', 'gray.300')}},[_vm._v(_vm._s(_vm.lecture.title)+" ")]),_c('CText',{attrs:{"fontSize":{
                        base: '.7rem',
                        lg: '.8rem',
                    },"fontWeight":"300","my":"0","color":_vm.mode('gray.600', 'gray.300')}},[_vm._v(" - "+_vm._s(_vm.lecture.artist)+" ")])],1),_c('CBox',[_c('CBadge',{attrs:{"mx":"1","py":"1","px":"2","fontSize":{
                        base: '.7rem',
                        lg: '.7rem',
                    },"color":_vm.mode('red.800', 'gray.300'),"bg":_vm.mode('red.100', '#1a202c'),"textTransform":"titleCase","fontWeight":"400"}},[_vm._v(_vm._s(_vm.lecture.language))]),_vm._l((_vm.lecture.tags),function(tag){return _c('CBadge',{key:tag,attrs:{"mx":"1","py":"1","px":"2","fontSize":{
                        base: '.7rem',
                        lg: '.7rem',
                    },"color":_vm.mode('blue.800', 'gray.300'),"bg":_vm.mode('blue.100', '#1a202c'),"textTransform":"titleCase","fontWeight":"400"}},[_vm._v(_vm._s(tag))])})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }