import { plyr_config, plyr_timelogs } from './constants'

export const truncate = (str, len = 10) => str.substring(0, len) + '...'

export const clientStorage = {
    setItem: (name, data) => localStorage.setItem(name, JSON.stringify(data)),
    getItem: (name) => JSON.parse(localStorage.getItem(name)),
}

export const timeLogs = {
    getAllTimeLogs: () => {
        return clientStorage.getItem(plyr_timelogs)
    },
    setAllTimeLogs: (updatedLogs) => {
        return clientStorage.setItem(plyr_timelogs, updatedLogs)
    },
    getSingleTimeLog: (id) => {
        const allTimeLogs = timeLogs.getAllTimeLogs()
        if (allTimeLogs && allTimeLogs[id]) {
            return allTimeLogs[id]
        }
        return null
    },
    setSingleTimeLog: (id, updatedLog) => {
        clientStorage.setItem(plyr_timelogs, {
            ...timeLogs.getAllTimeLogs(),
            [id]: updatedLog,
        })
    },
}

export const getWatchTime = (id) => {
    const timeLog = timeLogs.getSingleTimeLog(id)

    if (!timeLog) {
        return 0
    }
    const { seekTime } = timeLog

    return seekTime
}
export const getWatchTimePercentage = (id) => {
    const timeLog = timeLogs.getSingleTimeLog(id)

    if (!timeLog) {
        return 0
    }
    const { seekTime, duration } = timeLog

    const percentageWatch = (seekTime / duration) * 100

    return percentageWatch
}

export const updateConfig = (field, value) => {
    const prevConfig = clientStorage.getItem(plyr_config)
    clientStorage.setItem(plyr_config, {
        ...prevConfig,
        [field]: value,
    })
}
