<template>
    <CFlex
        mt="5"
        justifyContent="center"
        alignItems="center"
        w="100%"
        minH="250px"
        borderTop="1px solid"
        borderColor="gray.300"
    >
        <CBox p="4" maxW="900px" textAlign="center">
            <CText
                fontWeight="600"
                fontSize="1.2rem"
                :color="mode('red', 'red')"
            >
                Disclaimer</CText
            >
            <CText
                my="2"
                :fontSize="{ base: '.7rem', lg: '.9rem' }"
                :color="mode('inherit', 'gray.400')"
            >
                The lectures provided by <a>BaytulMuslim</a> is for general
                informational purposes only. All lectures on this site are
                provided in good faith and we put everything together here for
                easy access to all Muslims across the Globe. The videos are
                publicly available online and our intention for this project is
                Jihad and Jihad only.
                <CText>Jazaakumullaahu khayroo.</CText>
            </CText>
            <CText
                :fontSize="{ base: '.8rem', lg: '.9rem' }"
                :color="mode('gray.600', 'gray.400')"
                >&copy; {{ new Date().getFullYear() }} - BaytulMuslim</CText
            >
        </CBox>
    </CFlex>
</template>

<script>
import { mode, CBox, CFlex, CText } from '@chakra-ui/vue'

CFlex
export default {
    name: 'AppFooter',
    components: {
        CBox,
        CFlex,
        CText,
    },
    data() {
        return {
            mode,
        }
    },
}
</script>

<style lang="scss" scoped></style>
