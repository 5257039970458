<template>
    <CFlex h="100%" w="100%" justifyContent="center" alignItems="center">
        <CSpinner
            thickness="4px"
            speed="0.65s"
            empty-color="blue.200"
            color="blue.500"
            size="xl"
    /></CFlex>
</template>

<script>
import { CFlex, CSpinner } from '@chakra-ui/vue'
export default {
    components: {
        CFlex,
        CSpinner,
    },
}
</script>

<style lang="scss" scoped></style>
