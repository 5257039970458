import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeVue from '@/pages/Home.vue'
import AboutVue from '@/pages/About.vue'
import ContactUsVue from '@/pages/ContactUs.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeVue,
    },
    {
        path: '/about',
        name: 'About',
        component: AboutVue,
    },
    {
        path: '/contact',
        name: 'Contact Us',
        component: ContactUsVue,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
