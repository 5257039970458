<template>
    <CBox
        position="relative"
        maxW="600px"
        :w="{ base: '100%', md: '49%' }"
        minH="140px"
        :borderColor="mode('gray.300', 'gray.700')"
        :bg="mode('white', '#011627')"
        borderRadius="4px"
        my="3"
        border="0.5px solid rgba(0, 0, 0, 0.35)"
    >
        <CBox w="100%" position="absolute" left="0" bottom="0">
            <ProgressBar :percentage="this.watchTime" />
        </CBox>

        <CFlex
            h="100%"
            alignItems="center"
            justifyContent="space-between"
            gap="20px"
            px="3"
            py="5"
            boxShadow="sm"
        >
            <CBox borderRadius="50" mx="auto" cursor="pointer" @click="onPlay">
                <CBox>
                    <img :src="icon" />
                </CBox>
            </CBox>
            <CFlex
                h="100%"
                w="100%"
                flexDirection="column"
                justifyContent="space-between"
            >
                <CBox>
                    <CText
                        :fontSize="{
                            base: '.8rem',
                            lg: '1rem',
                        }"
                        fontWeight="600"
                        textWrap="wrap"
                        lineHeight="1.2"
                        mt="0"
                        mb="1"
                        :color="mode('inherit', 'gray.300')"
                        >{{ lecture.title }}
                    </CText>
                    <CText
                        :fontSize="{
                            base: '.7rem',
                            lg: '.8rem',
                        }"
                        fontWeight="300"
                        my="0"
                        :color="mode('gray.600', 'gray.300')"
                    >
                        - {{ lecture.artist }}
                    </CText>
                </CBox>

                <CBox>
                    <CBadge
                        mx="1"
                        py="1"
                        px="2"
                        :fontSize="{
                            base: '.7rem',
                            lg: '.7rem',
                        }"
                        :color="mode('red.800', 'gray.300')"
                        :bg="mode('red.100', '#1a202c')"
                        textTransform="titleCase"
                        fontWeight="400"
                        >{{ lecture.language }}</CBadge
                    >
                    <CBadge
                        mx="1"
                        py="1"
                        px="2"
                        :fontSize="{
                            base: '.7rem',
                            lg: '.7rem',
                        }"
                        :color="mode('blue.800', 'gray.300')"
                        :bg="mode('blue.100', '#1a202c')"
                        textTransform="titleCase"
                        fontWeight="400"
                        v-for="tag in lecture.tags"
                        :key="tag"
                        >{{ tag }}</CBadge
                    >
                </CBox>
            </CFlex>
        </CFlex>
    </CBox>
</template>

<script>
import { mode, CBox, CFlex, CText, CBadge } from '@chakra-ui/vue'
import PlayIcon from '../assets/play.svg'
import PauseIcon from '../assets/pause.svg'
import { getWatchTimePercentage } from '@/helper/functions'
import ProgressBar from './ProgressBar.vue'

export default {
    inject: ['timelogs'],
    props: {
        lecture: Object,
        activeLecture: Object,
        setActiveLecture: Function,
        removeActiveLecture: Function,
    },
    data() {
        return {
            mode,
            watchTime:
                !this.timelogs || !this.timelogs[this.lecture.id]
                    ? 0
                    : (this.timelogs[this.lecture.id].seekTime /
                          this.timelogs[this.lecture.id].duration) *
                      100,
        }
    },
    watch: {
        timelogs(newValue) {
            if (newValue && this.timelogs[this.lecture.id]) {
                this.watchTime =
                    (this.timelogs[this.lecture.id].seekTime /
                        this.timelogs[this.lecture.id].duration) *
                    100
            }
        },
    },
    mounted() {
        window.addEventListener('seekTimeChanged', (event) => {
            if (event.detail.id === this.lecture.id) {
                this.watchTime = getWatchTimePercentage(this.lecture.id)
            }
        })
    },
    components: {
        CBox,
        CFlex,
        CText,
        CBadge,
        ProgressBar,
    },
    methods: {
        onPlay() {
            this.removeActiveLecture()
            setTimeout(() => {
                this.setActiveLecture(this.lecture, true)
            }, 100)
        },
    },
    computed: {
        icon() {
            return this.activeLecture &&
                this.activeLecture?.id === this.lecture?.id
                ? PauseIcon
                : PlayIcon
        },
    },
}
</script>
