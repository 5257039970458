<template>
    <CFormControl>
        <CFormLabel
            :color="mode('inherit', 'gray.300')"
            v-if="label"
            fontSize=".9rem"
            :for="name"
            >{{ label }}</CFormLabel
        >

        <CInput
            fontSize=".9rem"
            :id="name"
            :borderColor="mode('gray.300', 'gray.700')"
            :color="mode('inherit', 'gray.300')"
            :bg="mode('transparent', '#011627')"
            w="100%"
            :value="value"
            border="0.5px solid"
            v-debounce:300ms="onChange"
            :placeholder="placeholder"
        />
    </CFormControl>
</template>

<script>
import { mode, CFormControl, CFormLabel, CInput } from '@chakra-ui/vue'
export default {
    props: {
        name: {
            type: String,
        },
        label: {
            type: String,
        },
        onChange: {
            type: Function,
        },
        value: {
            type: String,
        },
        placeholder: {
            type: String,
        },
    },
    data() {
        return {
            mode,
        }
    },
    components: {
        CFormControl,
        CFormLabel,
        CInput,
    },
}
</script>

<style lang="scss" scoped></style>
