// 2. Add your color mode config
export default {
    config: {
        useSystemColorMode: true,
    },

    // colors: {
    //     brand: {
    //         50: '#daffff',
    //         100: '#b1fbfb',
    //         200: '#85f7f7',
    //         300: '#58f3f3',
    //         400: '#31f0f0',
    //         500: '#1ed7d7',
    //         600: '#0ca7a7',
    //         700: '#007777',
    //         800: '#004949',
    //         900: '#001a1a',
    //     },
    // },
}
