<template>
    <CBox w="100%">
        <CBox my="5" h="20vh" textAlign="center">
            <CFlex h="100%" alignItems="center" justifyContent="center">
                <CHeading
                    fontWeight="600"
                    :fontSize="{
                        base: '1.6rem',
                        sm: '1.8rem',
                        md: '2.2rem',
                        lg: '2.6rem',
                    }"
                    lineHeight="1.4"
                    :color="mode('inherit', 'gray.50')"
                >
                    Submit a request
                </CHeading>
            </CFlex>
        </CBox>

        <Container>
            <CBox
                my="10"
                py="5"
                px="3"
                borderRadius="3"
                border="1px solid"
                borderColor="gray.400"
                maxW="700px"
                mx="auto"
            >
                <form ref="form" @submit.prevent="sendEmail">
                    <CFormControl
                        isRequired
                        :color="mode('#1a202c', '#fcfcfc')"
                    >
                        <CFormLabel for="name">Name</CFormLabel>
                        <CInput type="text" name="user_name" id="user_name" />
                    </CFormControl>
                    <CFormControl
                        my="4"
                        isRequired
                        :color="mode('#1a202c', '#fcfcfc')"
                    >
                        <CFormLabel for="user_email">Email</CFormLabel>
                        <CInput
                            type="email"
                            name="user_email"
                            id="user_email"
                        />
                    </CFormControl>
                    <CFormControl
                        isRequired
                        :color="mode('#1a202c', '#fcfcfc')"
                    >
                        <CFormLabel for="message">Your Message</CFormLabel>
                        <CTextarea name="message" id="message" />
                    </CFormControl>

                    <CButton
                        my="3"
                        fontWeight="500"
                        variantColor="blue"
                        type="submit"
                    >
                        Submit
                    </CButton>
                </form>
            </CBox>
        </Container>
    </CBox>
</template>

<script>
import {
    mode,
    CBox,
    CButton,
    CFlex,
    CText,
    CHeading,
    CFormControl,
    CInput,
    CTextarea,
    CFormLabel,
} from '@chakra-ui/vue'
import Container from '@/components/Container.vue'
import emailjs from '@emailjs/browser'

export default {
    name: 'ContactUs',
    head: {
        title: {
            inner: 'Contact Us',
        },
        // Meta tags
        meta: [
            { name: 'application-name', content: 'BaytulMuslim' },
            {
                name: 'description',
                content:
                    "Do you have any request, comment or complaint while using the platform? Kindly submit a request and in shaa Allaah we'll try as much as possible to attend to it.",
                id: 'desc',
            }, // id to replace intead of create element
            // ...
            // Twitter
            { name: 'twitter:title', content: 'BaytulMuslim' },
            // with shorthand
            {
                n: 'twitter:description',
                c: "Do you have any request, comment or complaint while using the platform? Kindly submit a request and in shaa Allaah we'll try as much as possible to attend to it.",
            },
            // ...
            // Google+ / Schema.org
            { itemprop: 'name', content: 'BaytulMuslim: The Muslim Home' },
            {
                itemprop: 'description',
                content:
                    "Do you have any request, comment or complaint while using the platform? Kindly submit a request and in shaa Allaah we'll try as much as possible to attend to it.",
            },
            // ...
            // Facebook / Open Graph
            { property: 'fb:app_id', content: '123456789' },
            {
                property: 'og:title',
                content:
                    "Do you have any request, comment or complaint while using the platform? Kindly submit a request and in shaa Allaah we'll try as much as possible to attend to it.",
            },
            // with shorthand
            {
                p: 'og:image',
                c: 'https://user-images.githubusercontent.com/40659739/183295372-df858ea4-08f1-49e4-8ac2-c15c6479f0d9.png',
            },
            // ...
        ],
    },
    mounted() {
        this.track()
    },
    components: {
        CBox,
        CFlex,
        CButton,
        CText,
        CHeading,
        CFormControl,
        CInput,
        CTextarea,
        CFormLabel,
        Container,
    },
    data() {
        return {
            mode,
        }
    },
    methods: {
        methods: {
            track() {
                this.$gtag.pageview('/about')
            },
        },
        sendEmail() {
            const name = this.$refs.form[0].value
            const email = this.$refs.form[1].value
            const message = this.$refs.form[2].value

            if (!name || !email || !message) return
            emailjs
                .sendForm(
                    process.env.VUE_APP_EMAIL_SERVICE_ID,
                    process.env.VUE_APP_EMAIL_TEMPLATE_ID,
                    this.$refs.form,
                    process.env.VUE_APP_EMAIL_PUBLIC_ID
                )
                .then(
                    () => {
                        this.$toast({
                            title: 'Message sent',
                            description:
                                'Your request has been submitted successfully.',
                            status: 'success',
                            duration: 3000,
                        })
                    },
                    (error) => {
                        this.$toast({
                            title: error.text,
                            status: 'danger',
                            duration: 3000,
                        })
                    }
                )
        },
    },
}
</script>

<style lang="scss" scoped></style>
