<template>
    <CProgress
        opacity="1"
        color="red"
        :bg="mode('gray.200', 'gray.500')"
        size="sm"
        isAnimated
        :value="this.percentage"
        borderRadius="4"
    />
</template>

<script>
import { mode, CBox, CProgress } from '@chakra-ui/vue'

export default {
    props: {
        percentage: {
            type: Number,
        },
    },
    data() {
        return {
            mode,
        }
    },
    components: {
        CBox,
        CProgress,
    },
}
</script>

<!-- :w="`${this.percentage}%`" -->
<style lang="scss" scoped></style>
