<template>
    <CBox>
        <MinimizedPlayer
            v-if="isMinimized && activeLecture"
            :lecture="activeLecture"
            :onMinimize="onMinimize"
            :isPlaying="isPlaying"
            :onPlayPause="onPlayPause"
        />
        <CModal
            :initial-focus-ref="() => $refs.initialRef"
            zIndex="99999"
            size="900"
            :isOpen="isOpen"
            :onClose="onMinimize"
            @keyup.space="onPlayPause"
            id="player"
        >
            <CModalContent ref="content" borderRadius="10px" p="2">
                <CModalHeader ref="initialRef" pt="10">
                    <CText
                        :fontSize="{
                            base: '0.9rem',
                            sm: '1rem',
                            md: '',
                            lg: '',
                        }"
                    >
                        {{ activeLecture?.title }}
                    </CText>
                </CModalHeader>
                <CModalCloseButton
                    @click="close"
                    borderRadius="full"
                    color="red"
                />
                <CButton
                    borderRadius="50%"
                    position="absolute"
                    top="8px"
                    size="sm"
                    padding="0"
                    right="48px"
                    icon="minus"
                    ariaLabel="Minimize Modal"
                    @click="onMinimize"
                >
                    <v-icon
                        width="15"
                        :color="mode('green.600', '#fff')"
                        name="minus"
                    ></v-icon
                ></CButton>

                <CModalBody borderRadius="20px">
                    <CFlex
                        w="100%"
                        h="100%"
                        zIndex="10"
                        bottom="0"
                        left="0"
                        bg="black"
                        minH="60vh"
                    >
                        <CBox
                            w="100%"
                            h="100%"
                            maxH="70vh"
                            mx="auto"
                            my="auto"
                            v-if="isOpen"
                        >
                            <CFlex
                                h="100%"
                                w="100%"
                                justifyContent="center"
                                alignItems="center"
                                v-if="isRefreshing"
                            >
                                <CSpinner
                                    thickness="4px"
                                    speed="0.65s"
                                    empty-color="green.200"
                                    color="white"
                                    size="xl"
                                />
                            </CFlex>
                            <vue-plyr
                                ref="plyr"
                                :options="{
                                    autoplay: true,
                                }"
                                :key="activeLecture.id"
                                v-else
                            >
                                <CBox class="plyr__video-embed">
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        :src="getLectureLink()"
                                        allowfullscreen
                                        allowtransparency
                                        allow="autoplay"
                                    ></iframe>
                                </CBox>
                            </vue-plyr>
                        </CBox>
                    </CFlex>
                </CModalBody>
                <CModalFooter>
                    <CFlex w="100%" justifyContent="space-between">
                        <CBox flex="1">
                            <CBox
                                v-if="prevLecture"
                                p="2"
                                w="90%"
                                border=".3px solid"
                                borderRadius="1"
                                boxShadow="sm"
                                cursor="pointer"
                                fontSize=".75rem"
                                variant-color="blue"
                                @click="handlePrev(prevLecture)"
                            >
                                <CFlex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    fontWeight="600"
                                >
                                    <v-icon
                                        width="20"
                                        name="arrow-left"
                                    ></v-icon>
                                    Prev:
                                </CFlex>
                                <CText
                                    :display="{ base: 'none', md: 'block' }"
                                    textAlign="right"
                                >
                                    {{ prevLecture.title }}
                                    <!-- {{ truncate(prevLecture.title, 45) }} -->
                                </CText>
                            </CBox>
                        </CBox>
                        <CBox flex="1">
                            <CBox
                                v-if="nextLecture"
                                p="2"
                                w="90%"
                                ml="auto"
                                border=".3px solid"
                                borderRadius="1"
                                boxShadow="sm"
                                cursor="pointer"
                                fontSize=".75rem"
                                @click="handleNext(nextLecture)"
                            >
                                <CFlex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    fontWeight="600"
                                >
                                    Next:
                                    <v-icon
                                        width="20"
                                        name="arrow-right"
                                    ></v-icon>
                                </CFlex>
                                <CText :display="{ base: 'none', md: 'block' }">
                                    {{ nextLecture.title }}
                                    <!-- {{ truncate(nextLecture.title, 45) }} -->
                                </CText>
                            </CBox>
                        </CBox>
                    </CFlex>
                </CModalFooter>
            </CModalContent>
            <CModalOverlay />
        </CModal>
    </CBox>
</template>

<script>
import {
    mode,
    CBox,
    CFlex,
    CText,
    CButton,
    CIconButton,
    CModal,
    CModalOverlay,
    CModalContent,
    CModalHeader,
    CModalFooter,
    CModalBody,
    CModalCloseButton,
    CSpinner,
} from '@chakra-ui/vue'
import { truncate, timeLogs, getWatchTimePercentage } from '@/helper/functions'
import MinimizedPlayer from './MinimizedPlayer.vue'

export default {
    inject: ['updateUsersData'],
    props: {
        prevLecture: Object,
        nextLecture: Object,
        activeLecture: Object,
        onClose: Function,
        isMinimized: Boolean,
        onMinimize: Function,
        setActiveLecture: Function,
    },
    data() {
        return {
            mode,
            truncate,
            duration: 0,
            isPlaying: true,
            watchTime: getWatchTimePercentage(this.activeLecture?.id),
            isRefreshing: false,
            allLecturesTimeLogs: timeLogs.getAllTimeLogs(),
        }
    },
    components: {
        CBox,
        CFlex,
        CText,
        CButton,
        CIconButton,
        CModal,
        CModalOverlay,
        CModalContent,
        CModalHeader,
        CModalFooter,
        CModalBody,
        CModalCloseButton,
        CSpinner,
        MinimizedPlayer,
    },
    created() {
        // Play/Pause Player on spacebar pressed.
        document.addEventListener('keyup', (event) => {
            if (event.code === 'Space') {
                this.onPlayPause()
            }
        })
    },
    mounted() {
        setTimeout(() => {
            this.handlePlayerInstance()
        }, 500)
    },
    watch: {
        activeLecture(newValue, oldValue) {
            if (newValue?.id !== oldValue?.id) {
                setTimeout(() => {
                    this.handlePlayerInstance()
                }, 500)
            }
        },
    },
    destroyed() {
        window.removeEventListener(
            'seekTimeChanged',
            (e) => console.log('event destroyed', e),
            false
        )
    },
    computed: {
        playing() {
            return this.activeLecture
        },
        player() {
            return this.$refs.plyr.player
        },

        isOpen() {
            const isItOpen = this.activeLecture === null ? false : true

            return false || isItOpen
        },
    },
    methods: {
        handlePlayerInstance() {
            const player = this.player

            this.player.once('ready', () => {
                let seekTime =
                    timeLogs.getSingleTimeLog(this.activeLecture.id)
                        ?.seekTime || 0

                this.duration = player.duration
                player.play()
                this.onSeekTimeChanged(seekTime)
                player.currentTime = seekTime
            })

            player.on('pause', () => {
                this.isPlaying = false
            })
            player.on('play', () => {
                this.isPlaying = true
            })
            player.on('seeked', () => {
                const seekTime = Math.floor(player.currentTime)
                this.onSeekTimeChanged(seekTime)
            })
            player.on('timeupdate', () => {
                const currentTime = Math.floor(player.currentTime)

                // Should be optimised
                if (this.watchTime < currentTime && currentTime % 3 === 0) {
                    this.onSeekTimeChanged(currentTime)
                }
            })

            // Move to next lecture on current on ended.
            player.on('ended', () => {
                this.nextLecture && this.handleNext(this.nextLecture)
            })
        },
        handleNext(audio) {
            this.isRefreshing = true
            this.setActiveLecture(audio)

            // Update current player config and timelogs on close
            this.updateUsersData()

            setTimeout(() => {
                this.isRefreshing = false
            }, 500)
        },
        handlePrev(audio) {
            this.isRefreshing = true
            this.setActiveLecture(audio)

            // Update current player config and timelogs on close
            this.updateUsersData()

            setTimeout(() => {
                this.isRefreshing = false
            }, 500)
        },
        getLectureLink() {
            return this.activeLecture.src
        },
        close() {
            // Update current player config and timelogs on close
            this.updateUsersData()
            this.onClose()
        },
        onPlayPause() {
            this.isPlaying = !this.isPlaying
            if (this.player.paused) {
                this.player.play()
            } else {
                this.player.pause()
                // Update current player config and timelogs
                this.updateUsersData()
            }
        },
        onSeekTimeChanged(seekTime) {
            this.watchTime = seekTime
            timeLogs.setSingleTimeLog(this.activeLecture.id, {
                seekTime,
                duration: this.duration,
            })

            const evt = new CustomEvent('seekTimeChanged', {
                detail: {
                    id: this.activeLecture.id,
                },
            })
            window.dispatchEvent(evt)
        },
    },
}
</script>
