<template>
    <CFlex
        w="100%"
        p="4"
        gap="'1%'"
        boxShadow="sm"
        mb="5"
        borderRadius="3"
        border="0.5px solid"
        borderColor="gray.400"
        alignItems="flex-end"
        :flexWrap="{ base: 'wrap', lg: 'unset' }"
        justifyContent="space-between"
    >
        <CBox :w="{ base: '100%', lg: '49%' }" :py="{ base: '2', lg: '0' }">
            <SearchInput
                name="search"
                label="Enter keyword"
                :value="searchParam"
                :onChange="handleSearch"
                placeholder="Search by title or lecturer"
            />
        </CBox>
        <CFlex
            :w="{ base: '100%', lg: '49%' }"
            :flexDirection="{ base: 'column', md: 'row' }"
            gap="2"
        >
            <FilterVue
                :value="activeTag"
                :onChange="filterByTag"
                :options="categoryOptions"
                placeholder="All Categories"
            />
            <FilterVue
                :value="selectedLanguage"
                :onChange="filterByLanguage"
                :options="languageOptions"
                placeholder="All Languages"
            />
        </CFlex>
    </CFlex>
</template>

<script>
import { CBox, CFlex } from '@chakra-ui/vue'
import SearchInput from '@/components/SearchInput.vue'

import { languageOptions, categoryOptions } from '@/helper/constants'
import FilterVue from '@/components/Filter.vue'

export default {
    props: {
        searchParam: {
            type: String,
        },
        handleSearch: {
            type: Function,
        },
        activeTag: {
            type: String,
        },
        filterByTag: {
            type: Function,
        },
        selectedLanguage: {
            type: String,
        },
        filterByLanguage: {
            type: Function,
        },
    },
    data() {
        return {
            languageOptions,
            categoryOptions,
        }
    },
    components: { CBox, CFlex, FilterVue, SearchInput },
}
</script>

<style lang="scss" scoped></style>
