<template>
    <CBox v-if="!isHidden">
        <CAlert status="info" :bg="{ base: 'blue.100', lg: 'blue.100' }" py="1">
            <CAlertIcon />
            {{ isHidden }}
            <CAlertDescription
                :fontSize="{ base: '.6rem', lg: '.75rem' }"
                :color="mode('#1a202c', '#1a202c')"
                :bg="mode('initial', 'transparent')"
                px="0"
            >
                Sign in to have your watch history saved online.
                <CButton
                    variant="link"
                    display="inline-block"
                    textDecoration="underline"
                    color="inherit"
                    ml="1"
                    fontSize="inherit"
                    fontWeight="inherit"
                    @click="dismissAlert"
                >
                    Dismiss <CIcon name="close" />
                </CButton>
            </CAlertDescription>
        </CAlert>
    </CBox>
</template>

<script>
import { clientStorage } from '@/helper/functions'
import { plyr_alert_dismissed } from '@/helper/constants'
import {
    mode,
    CAlert,
    CAlertIcon,
    CAlertDescription,
    CCloseButton,
    CIcon,
    CButton,
    CBox,
} from '@chakra-ui/vue'
export default {
    components: {
        CAlert,
        CAlertIcon,
        CAlertDescription,
        CCloseButton,
        CIcon,
        CButton,
        CBox,
    },
    data() {
        return {
            mode,
            isHidden:
                clientStorage.getItem(plyr_alert_dismissed) &&
                clientStorage.getItem(plyr_alert_dismissed) === true,
        }
    },
    methods: {
        dismissAlert() {
            this.isHidden = true
            clientStorage.setItem(plyr_alert_dismissed, true)
        },
    },
}
</script>

<style lang="scss" scoped></style>
