<template>
    <CBox h="100%" px="3" maxW="1200px" marginX="auto">
        <slot></slot>
    </CBox>
</template>

<script>
import { CBox } from '@chakra-ui/vue'

CBox
export default {
    name: 'ContainerComponent',
    components: {
        CBox,
    },
}
</script>
