<template>
    <CBox w="100%">
        <CBox my="5" h="35vh" textAlign="center" bg="#1a202c">
            <CFlex h="100%" alignItems="center" justifyContent="center">
                <CHeading
                    fontWeight="600"
                    :fontSize="{
                        base: '1.6rem',
                        sm: '1.8rem',
                        md: '2.2rem',
                        lg: '2.6rem',
                    }"
                    lineHeight="1.4"
                    :color="mode('#fff', 'gray.50')"
                >
                    About Baytul Muslim
                </CHeading>
            </CFlex>
        </CBox>

        <Container>
            <CFlex
                w="75%"
                mx="auto"
                flexDir="column"
                gap="3"
                :color="mode('#1a202c', '#fff')"
            >
                <CHeading
                    :fontSize="{
                        base: '1.1rem',
                        sm: '1.2rem',
                        md: '1.3rem',
                        lg: '1.4rem',
                    }"
                >
                    About
                </CHeading>
                <CText>
                    <router-link to="/">BaytulMuslim.net</router-link> is the
                    largest collection of video resources on topics sorrounding
                    <strong
                        >Nikaah (marriage), Child Upbringing and Marital
                        Home.</strong
                    >
                </CText>

                <CText>
                    Videos on <strong>BaytulMuslim</strong> are handpicked
                    lectures of Scholars of Sunnah (may Allaah reward them all)
                    across YouTube.
                </CText>
                <CText>
                    We hope that these resources would be of great use for
                    anyone who wants to learn about the process of getting
                    married, raising righeous kids and resolving marital issues
                    in Islaam.
                </CText>
                <CText>
                    We ask Allaah (subhaanahu wa ta'ala) to accept it as an act
                    of good deeds done solely for His Almighty sake.
                </CText>

                <CText>Assalamu'alaykum warahmatullaah wabarakaatuh.</CText>
            </CFlex>
        </Container>
    </CBox>
</template>

<script>
import { mode, CBox, CFlex, CText, CHeading } from '@chakra-ui/vue'
import Container from '@/components/Container.vue'

export default {
    name: 'AboutUs',
    head: {
        title: {
            inner: 'About',
        },
        // Meta tags
        meta: [
            { name: 'application-name', content: 'BaytulMuslim' },
            {
                name: 'description',
                content:
                    'BaytulMuslim.net is the largest collection of video resources on topics sorrounding Nikaah (marriage), Child Upbringing and Marital Home. Videos on BaytulMuslim are handpicked lectures of Scholars of Sunnah (may Allaah reward them all) across YouTube.',
                id: 'desc',
            }, // id to replace intead of create element
            // ...
            // Twitter
            { name: 'twitter:title', content: 'BaytulMuslim' },
            // with shorthand
            {
                n: 'twitter:description',
                c: 'BaytulMuslim.net is the largest collection of video resources on topics sorrounding Nikaah (marriage), Child Upbringing and Marital Home. Videos on BaytulMuslim are handpicked lectures of Scholars of Sunnah (may Allaah reward them all) across YouTube.',
            },
            // ...
            // Google+ / Schema.org
            { itemprop: 'name', content: 'BaytulMuslim: The Muslim Home' },
            {
                itemprop: 'description',
                content:
                    'BaytulMuslim.net is the largest collection of video resources on topics sorrounding Nikaah (marriage), Child Upbringing and Marital Home. Videos on BaytulMuslim are handpicked lectures of Scholars of Sunnah (may Allaah reward them all) across YouTube.',
            },
            // ...
            // Facebook / Open Graph
            { property: 'fb:app_id', content: '123456789' },
            {
                property: 'og:title',
                content:
                    'BaytulMuslim.net is the largest collection of video resources on topics sorrounding Nikaah (marriage), Child Upbringing and Marital Home. Videos on BaytulMuslim are handpicked lectures of Scholars of Sunnah (may Allaah reward them all) across YouTube.',
            },
            // with shorthand
            {
                p: 'og:image',
                c: 'https://user-images.githubusercontent.com/40659739/183295372-df858ea4-08f1-49e4-8ac2-c15c6479f0d9.png',
            },
            // ...
        ],
    },
    mounted() {
        this.track()
    },
    components: {
        CBox,
        CFlex,
        CText,
        CHeading,
        Container,
    },
    data() {
        return {
            mode,
        }
    },
    methods: {
        track() {
            this.$gtag.pageview('/about')
        },
    },
}
</script>

<style lang="scss" scoped></style>
