<!-- eslint-disable -->
<template>
    <CSelect
        zIndex="0"
        h="10"
        border="0.5px solid"
        w="100%"
        fontWeight="600"
        fontSize=".9rem"
        @change="onChange"
        v-model="selectValue"
        :placeholder="placeholder"
        :borderColor="mode('gray.300', 'gray.700')"
        :color="mode('blue.500', 'gray.300')"
        :bg="mode('transparent', '#011627')"
    >
        <option
            :color="mode('inherit', 'gray.300')"
            v-for="option in options"
            :key="option.value"
            :value="option.value"
        >
            {{ option.label }}
        </option>
    </CSelect>
</template>

<script>
import { mode, CSelect, CBox } from '@chakra-ui/vue'
export default {
    props: {
        options: {
            type: Array,
            default: () => [{ label: '', value: '' }],
        },
        value: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        onChange: {
            type: Function,
        },
    },
    data() {
        return {
            mode,
            selectValue: this.value,
        }
    },
    components: {
        CBox,
        CSelect,
    },
}
</script>
