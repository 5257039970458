var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',{attrs:{"pb":"80px"}},[(_vm.activeLecture)?_c('MediaPlayer',{attrs:{"activeLecture":_vm.activeLecture,"prevLecture":_vm.prevLecture,"nextLecture":_vm.nextLecture,"onClose":_vm.removeActiveLecture,"isMinimized":_vm.isMinimized,"onMinimize":_vm.onMinimize,"setActiveLecture":_vm.setActiveLecture}}):_vm._e(),_c('CFlex',{attrs:{"minH":"30vh","w":"100%","alignItems":"center"}},[_c('CBox',{attrs:{"textAlign":"center","w":"756px","maxW":"80%","mx":"auto"}},[_c('CHeading',{attrs:{"fontWeight":"600","fontSize":{
                    base: '1.6rem',
                    sm: '1.8rem',
                    md: '2.2rem',
                    lg: '2.6rem',
                },"lineHeight":"1.4","color":_vm.mode('blackAlpha.800', 'gray.100')}},[_vm._v(" Raise the next "),_c('span',{style:({
                        color: '#E63946',
                    })},[_vm._v("pious")]),_vm._v(" generation on the firm understanding of Islaam ")])],1)],1),_c('CBox',[_c('Container',[(
                    _vm.filteredLectures.length > 1 &&
                    _vm.lastPlayedLecture &&
                    !_vm.isMinimized
                )?_c('CBox',{attrs:{"pb":"3"}},[_c('CText',{attrs:{"color":_vm.mode('inherit', 'gray.300')}},[_vm._v("Pick up from where you left")]),_c('MediaItem',{attrs:{"lecture":_vm.lastPlayedLecture,"activeLecture":_vm.activeLecture,"setActiveLecture":_vm.setActiveLecture,"removeActiveLecture":_vm.removeActiveLecture}})],1):_vm._e(),_c('CBox',[_c('LectureFilter',{attrs:{"searchParam":_vm.searchParam,"handleSearch":_vm.handleSearch,"activeTag":_vm.activeTag,"filterByTag":_vm.filterByTag,"selectedLanguage":_vm.selectedLanguage,"filterByLanguage":_vm.filterByLanguage}})],1),_c('CBox',{attrs:{"border":"0.5px solid","borderColor":"gray.400","px":"3","py":"5","minH":"200px"}},[(_vm.isLoading)?_c('CBox',{attrs:{"h":"200px","w":"100%"}},[_c('LoadingSpinner')],1):(_vm.error)?_c('CBox',{attrs:{"h":"200px","w":"100%"}},[_c('ErrorMsg',{attrs:{"error":_vm.error}})],1):(_vm.filteredLectures?.length < 1)?_c('CFlex',{attrs:{"w":"100%","alignItems":"center","justifyContent":"center"}},[_c('CText',{attrs:{"color":"red","textAlign":"center"}},[_vm._v("No lectures available for selected filters.")])],1):_c('CBox',[_c('Lectures',{attrs:{"limit":_vm.limit,"handleLimit":_vm.handleLimit,"activeLecture":_vm.activeLecture,"filteredLectures":_vm.filteredLectures,"lecturesLen":_vm.filteredLectures.length,"setActiveLecture":_vm.setActiveLecture,"removeActiveLecture":_vm.removeActiveLecture}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }