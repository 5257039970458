<template>
    <Container>
        <form @submit="onSubmit">
            <CFlex w="100%" flexWrap="wrap" gap="1%">
                <CBox w="49%">
                    <CFormControl isRequired>
                        <CFormLabel for="title">Title</CFormLabel>
                        <CInput
                            v-model="title"
                            type="text"
                            id="title"
                            aria-describedby="title-helper-text"
                        />
                    </CFormControl>
                </CBox>
                <CBox w="49%">
                    <CFormControl isRequired>
                        <CFormLabel for="src">Source Link</CFormLabel>
                        <CInput
                            v-model="src"
                            type="text"
                            id="src"
                            aria-describedby="src-helper-text"
                        />
                    </CFormControl>
                </CBox>
                <CBox w="49%">
                    <CFormControl isRequired>
                        <CFormLabel for="artist">Artist Name</CFormLabel>
                        <CInput
                            v-model="artist"
                            type="text"
                            id="artist"
                            aria-describedby="artist-helper-text"
                        />
                    </CFormControl>
                </CBox>
                <CBox w="49%">
                    <CFormControl isRequired>
                        <CFormLabel for="language">Language</CFormLabel>
                        <CSelect
                            v-model="language"
                            variant="filled"
                            placeholder="Language"
                        >
                            <option
                                :key="index"
                                v-for="(option, index) in langOptions"
                                :value="option"
                            >
                                {{ option }}
                            </option>
                        </CSelect>
                    </CFormControl>
                </CBox>
                <CBox w="49%">
                    <CFormControl isRequired>
                        <CFormLabel for="tags">Tags</CFormLabel>
                        <!-- <v-select
                            :options="books"
                            label="title"
                            v-model="selected"
                        >
                        </v-select> -->
                        <VSelect
                            v-model="tags"
                            multiple
                            id="tags"
                            placeholder="Add tags/categorues"
                            :options="tagOptions"
                            :selectable="(option) => !tags.includes(option)"
                        >
                            <template #search="{ attributes, events }">
                                <input
                                    class="vs__search"
                                    :required="!tags.length"
                                    v-bind="attributes"
                                    v-on="events"
                                />
                            </template>
                        </VSelect>
                    </CFormControl>
                </CBox>
                <CFlex alignItems="flex-end" w="49%">
                    <CButton type="submit" color="blue">Submit</CButton>
                </CFlex>
            </CFlex>
        </form>
    </Container>
</template>

<script>
import { tagOptions, langOptions } from '@/helper/constants'
import { v4 as uuidv4 } from 'uuid'
import { doc, setDoc } from 'firebase/firestore'
// import { data } from '@/helper/data'

import {
    CButton,
    CBox,
    CFlex,
    CInput,
    CSelect,
    CFormControl,
    CFormLabel,
    CFormErrorMessage,
    CFormHelperText,
} from '@chakra-ui/vue'
import Container from '@/components/Container.vue'
import { db } from '@/config/firebase'

export default {
    data() {
        return {
            src: '',
            language: '',
            title: '',
            artist: '',
            tags: [],
        }
    },
    components: {
        CButton,
        CBox,
        CFlex,
        CInput,
        CSelect,
        CFormControl,
        CFormLabel,
        CFormErrorMessage,
        CFormHelperText,
        Container,
    },
    computed: {
        tagOptions() {
            return tagOptions
        },
        langOptions() {
            return langOptions
        },
    },
    methods: {
        async onSubmit(e) {
            e.preventDefault()

            try {
                // const lectureId = uuidv4()

                // Add a new document in collection "cities"
                // const data = {
                //     id: lectureId,
                //     artist: this.artist,
                //     src: this.src,
                //     language: this.language,
                //     tags: this.tags,
                //     title: this.title,
                // }

                const formattedData = [].map((item) => ({
                    ...item,
                    id: uuidv4(),
                }))

                for (let i = 0; i < formattedData.length; i++) {
                    let currLecture = formattedData[i]
                    await setDoc(
                        doc(db, 'lectures', currLecture.id),
                        currLecture
                    )
                }
            } catch (error) {
                console.log({ error })
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>
