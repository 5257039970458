<template>
    <CFlex h="100%" w="100%" justifyContent="center" alignItems="center">
        <CText color="red" textAlign="center">
            {{ error }}
        </CText>
    </CFlex>
</template>

<script>
import { CFlex, CText } from '@chakra-ui/vue'
export default {
    props: {
        error: {
            type: String,
        },
    },
    components: {
        CFlex,
        CText,
    },
}
</script>

<style lang="scss" scoped></style>
