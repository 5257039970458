<template>
  <c-button :style="[baseStyle]">
    <slot></slot>
  </c-button>
</template>

<script>
import { CButton } from "@chakra-ui/vue";

export default {
  name: "ButtonComponent",
  props: {
    variant: String,
  },
  components: {
    "c-button": CButton,
  },
  data() {
    return {
      baseStyle: {
        ouline: "none",
        background: "#fff",
        fontSize: "16px",
        fontWeight: 600,
        cursor: "pointer",
        height: "64px",
        width: "321px",
        borderRadius: "15px",
        padding: "20px 70px 20px 70px",
        lineHeight: "24px",
        letterSpacing: "0.1em",
        border: "1px solid",
      },
    };
  },
};
</script>
