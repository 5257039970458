<template>
    <CBox>
        <CTabs @change="onChange" variant="enclosed" :default-index="0">
            <CTab-list>
                <CTab
                    px="6"
                    h="10"
                    v-for="tab in tabLists"
                    :key="tab.title"
                    :color="activeTag === tab.title ? 'black.300' : 'black'"
                    :bg="activeTag === tab.title ? 'blue.100' : '#fff'"
                    border="2px solid"
                    borderColor="gray.200"
                    borderRadius="0"
                    _selected="{borderColor: 'none', outline: 'none'}"
                >
                    {{ tab.title }}
                </CTab>
            </CTab-list>

            <!-- <CTabPanels>
        <CTabPanel>
          <p>one!</p>
        </CTabPanel>
        <CTab-panel>
          <p>two!</p>
        </CTab-panel>
        <CTab-panel>
          <p>three!</p>
        </CTab-panel>
      </CTabPanels> -->
        </CTabs>
    </CBox>
</template>

<script>
import {
    CBox,
    CFlex,
    CTab,
    CTabs,
    CTabList,
    CTabPanels,
    CTabPanel,
    CText,
    CButton,
} from '@chakra-ui/vue'

export default {
    props: {
        activeTag: String,
        tabLists: {
            type: Array,
        },
        onTabChange: {
            type: Function,
        },
    },
    methods: {
        onChange(index) {
            this.onTabChange(this.tabLists[index].title)
        },
    },
    components: {
        CBox,
        CFlex,
        CTab,
        CTabs,
        CTabList,
        CTabPanels,
        CTabPanel,
        CText,
        CButton,
    },
}
</script>
