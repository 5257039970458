<template>
    <CBox id="app" :bg="bg" pb="50px">
        <Header
            :user="user"
            :isSignedIn="user ? true : false"
            :handleLogout="handleLogout"
            :handleSignin="handleSignin"
        />
        <CBox minH="90vh">
            <router-view />
        </CBox>
        <Footer />
    </CBox>
</template>

<script>
import { computed } from 'vue'
import HomePage from './pages/Home.vue'
import { CBox, mode } from '@chakra-ui/vue'
import { clientStorage, timeLogs } from './helper/functions'
import { plyr_config, plyr_lastplayed, plyr_timelogs } from '@/helper/constants'
import {
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithPopup,
    signOut,
} from '@firebase/auth'

import { auth, db } from './config/firebase'
import Header from './components/Header.vue'
import {
    // collection,
    doc,
    getDoc,
    setDoc,
    // getDocs,
    // query,
    // where,
} from '@firebase/firestore'
import Button from './components/Button.vue'
import Footer from './components/Footer.vue'
export default {
    name: 'App',
    meta: [
        { name: 'application-name', content: 'BaytulMuslim' },
        {
            name: 'description',
            content:
                'BaytulMuslim | The best resources to learn about Nikaah (marriage), Child Upbringing and Marital Issues resolutions in Islaam',
            id: 'desc',
        }, // id to replace intead of create element
        // ...
        // Twitter
        { name: 'twitter:title', content: 'BaytulMuslim' },
        // with shorthand
        {
            n: 'twitter:description',
            c: 'BaytulMuslim | The best resources to learn about Nikaah (marriage), Child Upbringing and Marital Issues resolutions in Islaam',
        },
        // ...
        // Google+ / Schema.org
        { itemprop: 'name', content: 'BaytulMuslim: The Muslim Home' },
        {
            itemprop: 'description',
            content:
                'The best resources to learn about Nikaah (marriage), Child Upbringing and Marital Issues resolutions in Islaam',
        },
        // ...
        // Facebook / Open Graph
        { property: 'fb:app_id', content: '123456789' },
        {
            property: 'og:title',
            content:
                'The best resources to learn about Nikaah (marriage), Child Upbringing and Marital Issues resolutions in Islaam',
        },
        // with shorthand
        {
            p: 'og:image',
            c: 'https://user-images.githubusercontent.com/40659739/183295372-df858ea4-08f1-49e4-8ac2-c15c6479f0d9.png',
        },
        // ...
    ],
    data() {
        return {
            user: null,
            isLoggingOut: false,
            config: clientStorage.getItem(plyr_config),
            timelogs: clientStorage.getItem(plyr_timelogs),
            lastPlayedLecture: clientStorage.getItem(plyr_lastplayed),
        }
    },
    provide() {
        return {
            config: computed(() => this.config),
            timelogs: computed(() => this.timelogs),
            isLoggingOut: computed(() => this.isLoggingOut),
            lastPlayedLecture: computed(() => this.lastPlayedLecture),
            setLastPlayedLecture: this.setLastPlayedLecture,
            updateUsersData: this.updateUsersData,
        }
    },
    inject: ['$chakraColorMode', '$toggleColorMode'],
    components: {
        HomePage,
        CBox,
        Header,
        Button,
        Footer,
    },
    mounted() {
        this.track()
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                const { uid, email, displayName, photoURL } = currentUser

                // Fetch Users Existing data
                this.fetchUsersData(uid)

                // Update user state
                this.user = { uid, email, displayName, photoURL }
            }
        })
        // Manually trigger theme change
        const defaultTheme =
            clientStorage.getItem(plyr_config)?.theme || 'system'

        if (
            defaultTheme !== 'system' &&
            defaultTheme !== this.$chakraColorMode()
        ) {
            this.$toggleColorMode()
        }
    },
    computed: {
        bg() {
            return mode('gray.50', '#1a202c')
        },
    },
    methods: {
        track() {
            this.$gtag.pageview('/')
        },
        handleSignin() {
            const provider = new GoogleAuthProvider()

            signInWithPopup(auth, provider)
                .then((result) => {
                    const user = result.user

                    this.$toast({
                        title: `Welcome back, ${
                            user.displayName.split(' ')[0]
                        }`,
                        status: 'success',
                        position: 'top',
                        duration: 1000,
                    })
                })
                .catch((error) => {
                    const errorMessage = error.message
                    this.$toast({
                        title: 'Authentication Error.',
                        description: errorMessage || 'Something went wrong.',
                        status: 'danger',
                        position: 'top',
                        duration: 1000,
                    })
                })
        },
        handleLogout() {
            this.isLoggingOut = true
            const callback = () =>
                signOut(auth)
                    .then(() => {
                        this.user = null
                        this.isLoggingOut = false
                    })
                    .catch((error) => {
                        const errorMessage = error.message
                        this.isLoggingOut = false
                        this.$toast({
                            title: 'Logout Failed',
                            description:
                                errorMessage || 'Something went wrong.',
                            status: 'danger',
                            position: 'top',
                            duration: 1000,
                        })
                    })

            this.updateUsersData(callback)
        },
        async fetchUsersData(id) {
            const configRef = doc(db, 'configs', id)
            const configSnap = await getDoc(configRef)
            if (configSnap.exists()) {
                this.config = configSnap.data()
                clientStorage.setItem(plyr_config, configSnap.data())
            }

            const timeLogRef = doc(db, 'timelogs', id)
            const timeLogSnap = await getDoc(timeLogRef)
            if (timeLogSnap.exists()) {
                const previousLogs = timeLogs.getAllTimeLogs()
                const mergedLogs = {
                    ...timeLogSnap.data(),
                    ...previousLogs,
                }
                this.timelogs = mergedLogs
                clientStorage.setItem(plyr_timelogs, mergedLogs)
            }

            const lastPlayedRef = doc(db, 'last_played', id)
            const lastPlayedSnap = await getDoc(lastPlayedRef)
            if (lastPlayedSnap.exists()) {
                const mergedLastPlayed = {
                    ...(previousLastPlayed
                        ? previousLastPlayed
                        : lastPlayedSnap.data()),
                }
                this.lastPlayedLecture = mergedLastPlayed
                const previousLastPlayed = clientStorage.getItem(
                    plyr_lastplayed,
                    mergedLastPlayed
                )

                clientStorage.setItem(plyr_lastplayed)
            }
        },
        async updateUsersData(callback) {
            if (!this.user) return
            try {
                const plyrConfig = clientStorage.getItem(plyr_config)
                const plyrTimelogs = clientStorage.getItem(plyr_timelogs)
                const plyrLastPlayed = clientStorage.getItem(plyr_lastplayed)

                // Set config
                const configRef = doc(db, 'configs', this.user.uid)
                setDoc(configRef, plyrConfig)

                // Set timeLogs
                const timeLogRef = doc(db, 'timelogs', this.user.uid)
                setDoc(timeLogRef, plyrTimelogs)

                // Set LastPlayed
                const lastPlayedRef = doc(db, 'last_played', this.user.uid)
                await setDoc(lastPlayedRef, plyrLastPlayed)

                callback && callback()
            } catch (error) {
                this.$toast({
                    title: error.message,
                    status: 'danger',
                    position: 'top',
                    duration: 1000,
                })
            }
        },
        setLastPlayedLecture(lecture) {
            this.lastPlayedLecture = lecture
        },
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

* {
    font-family: 'Poppins', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    background-color: #1a202c;
}

#modal-portal-full {
    display: block;
    transition: display ease-in-out 3s;
}
#modal-portal-minimized {
    display: none;
}
#app {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.plyr.plyr--full-ui {
    max-height: 60vh !important;
}
</style>
