<template>
    <CBox
        position="fixed"
        w="100%"
        bottom="0"
        left="0"
        h="80px"
        :bg="mode('gray.700', 'gray.700')"
        zIndex="999999"
    >
        <Container>
            <CFlex
                position="relative"
                justifyContent="space-between"
                alignItems="center"
                h="100%"
                py="2"
                gap="2"
            >
                <CBox
                    borderRadius="50"
                    mx="auto"
                    cursor="pointer"
                    @click="onPlayPause"
                >
                    <CBox>
                        <img :src="icon" />
                    </CBox>
                </CBox>
                <CFlex
                    h="100%"
                    flexDir="column"
                    justifyContent="space-around"
                    flex="1"
                >
                    <CText
                        fontWeight="600"
                        :fontSize="{ base: '.75rem', lg: '1.2rem' }"
                        :color="mode('#fff', 'gray.300')"
                    >
                        {{ lecture?.title }}
                    </CText>
                    <CBox w="100%">
                        <ProgressBar :percentage="activeWatchTime" />
                    </CBox>
                </CFlex>
                <CButton h="100%" bg="blue.100" @click="onMinimize">
                    <v-icon width="25" color="blue" name="maximize-2"></v-icon>
                </CButton>
            </CFlex>
        </Container>
    </CBox>
</template>

<script>
import { mode, CBox, CFlex, CButton, CText } from '@chakra-ui/vue'
import Container from '@/components/Container.vue'
import ProgressBar from '@/components/ProgressBar.vue'

import PlayIcon from '../assets/play.svg'
import PauseIcon from '../assets/pause.svg'
import { getWatchTimePercentage } from '@/helper/functions'
export default {
    props: {
        isPlaying: {
            type: Boolean,
        },
        lecture: {
            type: Object,
        },
        onMinimize: {
            type: Function,
        },
        onPlayPause: {
            type: Function,
        },
    },
    data() {
        return {
            mode,
            activeWatchTime: 0,
        }
    },
    mounted() {
        this.activeWatchTime = getWatchTimePercentage(this.lecture.id)
        window.addEventListener('seekTimeChanged', (event) => {
            if (event.detail.id === this.lecture.id) {
                this.activeWatchTime = getWatchTimePercentage(this.lecture.id)
            }
        })
    },
    components: {
        CBox,
        CFlex,
        CButton,
        CText,
        Container,
        ProgressBar,
    },
    computed: {
        icon() {
            return this.isPlaying ? PauseIcon : PlayIcon
        },
    },
}
</script>

<style lang="scss" scoped></style>
