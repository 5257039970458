<!-- eslint-disable -->
<template>
    <CBox>
        <CFlex :gap="'1%'" flexWrap="wrap" justifyContent="space-between">
            <MediaItem
                v-for="lecture in filteredLectures.slice(0, limit)"
                :key="lecture.id"
                :lecture="lecture"
                :activeLecture="activeLecture"
                :setActiveLecture="setActiveLecture"
                :removeActiveLecture="removeActiveLecture"
            />
        </CFlex>
        <CBox :color="mode('inherit', 'gray.300')">
            Showing
            {{ Math.min(limit, lecturesLen) }} of {{ lecturesLen }} lectures.
        </CBox>
        <CBox v-if="limit < lecturesLen" py="2" textAlign="center">
            <CButton
                @click="handleLimit"
                fontWeight="400"
                px="25px"
                cursor="pointer"
                :bg="mode('blue.100', 'gray.700')"
                :color="mode('inherit', 'whiteAlpha.700')"
            >
                See more
            </CButton>
        </CBox>
    </CBox>
</template>

<script>
import { mode, CFlex, CBox, CButton } from '@chakra-ui/vue'
import MediaItem from '@/components/MediaItem.vue'
export default {
    props: {
        limit: {
            type: Number,
        },
        lecturesLen: {
            type: Number,
        },
        filteredLectures: {
            type: Array,
        },
        handleLimit: {
            type: Function,
        },
        activeLecture: {
            type: Object,
        },
        setActiveLecture: {
            type: Function,
        },
        removeActiveLecture: {
            type: Function,
        },
    },
    data() {
        return {
            mode,
        }
    },
    components: { CBox, CFlex, CButton, MediaItem },
}
</script>

<style lang="scss" scoped></style>
