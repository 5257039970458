var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',{attrs:{"w":"100%"}},[_c('CBox',{attrs:{"my":"5","h":"35vh","textAlign":"center","bg":"#1a202c"}},[_c('CFlex',{attrs:{"h":"100%","alignItems":"center","justifyContent":"center"}},[_c('CHeading',{attrs:{"fontWeight":"600","fontSize":{
                    base: '1.6rem',
                    sm: '1.8rem',
                    md: '2.2rem',
                    lg: '2.6rem',
                },"lineHeight":"1.4","color":_vm.mode('#fff', 'gray.50')}},[_vm._v(" About Baytul Muslim ")])],1)],1),_c('Container',[_c('CFlex',{attrs:{"w":"75%","mx":"auto","flexDir":"column","gap":"3","color":_vm.mode('#1a202c', '#fff')}},[_c('CHeading',{attrs:{"fontSize":{
                    base: '1.1rem',
                    sm: '1.2rem',
                    md: '1.3rem',
                    lg: '1.4rem',
                }}},[_vm._v(" About ")]),_c('CText',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("BaytulMuslim.net")]),_vm._v(" is the largest collection of video resources on topics sorrounding "),_c('strong',[_vm._v("Nikaah (marriage), Child Upbringing and Marital Home.")])],1),_c('CText',[_vm._v(" Videos on "),_c('strong',[_vm._v("BaytulMuslim")]),_vm._v(" are handpicked lectures of Scholars of Sunnah (may Allaah reward them all) across YouTube. ")]),_c('CText',[_vm._v(" We hope that these resources would be of great use for anyone who wants to learn about the process of getting married, raising righeous kids and resolving marital issues in Islaam. ")]),_c('CText',[_vm._v(" We ask Allaah (subhaanahu wa ta'ala) to accept it as an act of good deeds done solely for His Almighty sake. ")]),_c('CText',[_vm._v("Assalamu'alaykum warahmatullaah wabarakaatuh.")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }