import Vue from 'vue'
import router from '@/router'
import Chakra, {
    CThemeProvider,
    CColorModeProvider,
    CReset,
    CBox,
} from '@chakra-ui/vue'

import VueHead from 'vue-head'
// import Meta from 'vue-meta'
import VuePlyr from 'vue-plyr'
import feather from 'vue-icon'
import vSelect from 'vue-select'
import vueDebounce from 'vue-debounce'

import VueGtag from 'vue-gtag'

import 'vue-plyr/dist/vue-plyr.css'
import 'vue-select/dist/vue-select.css'

import App from './App.vue'
import theme from './theme'

Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID },
})
Vue.component('VSelect', vSelect)
Vue.use(VueHead)
Vue.use(vueDebounce)
Vue.use(feather, 'v-icon')
Vue.use(Chakra, {
    extendTheme: theme,
})
Vue.use(VuePlyr, {
    plyr: {},
})
Vue.config.productionTip = false

new Vue({
    router,
    render: (h) =>
        h(CThemeProvider, [
            h(CColorModeProvider, [h(CBox, [h(CReset), h(App)])]),
        ]),
}).$mount('#app')
