var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',[(_vm.isMinimized && _vm.activeLecture)?_c('MinimizedPlayer',{attrs:{"lecture":_vm.activeLecture,"onMinimize":_vm.onMinimize,"isPlaying":_vm.isPlaying,"onPlayPause":_vm.onPlayPause}}):_vm._e(),_c('CModal',{attrs:{"initial-focus-ref":() => _vm.$refs.initialRef,"zIndex":"99999","size":"900","isOpen":_vm.isOpen,"onClose":_vm.onMinimize,"id":"player"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.onPlayPause.apply(null, arguments)}}},[_c('CModalContent',{ref:"content",attrs:{"borderRadius":"10px","p":"2"}},[_c('CModalHeader',{ref:"initialRef",attrs:{"pt":"10"}},[_c('CText',{attrs:{"fontSize":{
                        base: '0.9rem',
                        sm: '1rem',
                        md: '',
                        lg: '',
                    }}},[_vm._v(" "+_vm._s(_vm.activeLecture?.title)+" ")])],1),_c('CModalCloseButton',{attrs:{"borderRadius":"full","color":"red"},on:{"click":_vm.close}}),_c('CButton',{attrs:{"borderRadius":"50%","position":"absolute","top":"8px","size":"sm","padding":"0","right":"48px","icon":"minus","ariaLabel":"Minimize Modal"},on:{"click":_vm.onMinimize}},[_c('v-icon',{attrs:{"width":"15","color":_vm.mode('green.600', '#fff'),"name":"minus"}})],1),_c('CModalBody',{attrs:{"borderRadius":"20px"}},[_c('CFlex',{attrs:{"w":"100%","h":"100%","zIndex":"10","bottom":"0","left":"0","bg":"black","minH":"60vh"}},[(_vm.isOpen)?_c('CBox',{attrs:{"w":"100%","h":"100%","maxH":"70vh","mx":"auto","my":"auto"}},[(_vm.isRefreshing)?_c('CFlex',{attrs:{"h":"100%","w":"100%","justifyContent":"center","alignItems":"center"}},[_c('CSpinner',{attrs:{"thickness":"4px","speed":"0.65s","empty-color":"green.200","color":"white","size":"xl"}})],1):_c('vue-plyr',{key:_vm.activeLecture.id,ref:"plyr",attrs:{"options":{
                                autoplay: true,
                            }}},[_c('CBox',{staticClass:"plyr__video-embed"},[_c('iframe',{attrs:{"width":"100%","height":"100%","src":_vm.getLectureLink(),"allowfullscreen":"","allowtransparency":"","allow":"autoplay"}})])],1)],1):_vm._e()],1)],1),_c('CModalFooter',[_c('CFlex',{attrs:{"w":"100%","justifyContent":"space-between"}},[_c('CBox',{attrs:{"flex":"1"}},[(_vm.prevLecture)?_c('CBox',{attrs:{"p":"2","w":"90%","border":".3px solid","borderRadius":"1","boxShadow":"sm","cursor":"pointer","fontSize":".75rem","variant-color":"blue"},on:{"click":function($event){return _vm.handlePrev(_vm.prevLecture)}}},[_c('CFlex',{attrs:{"justifyContent":"space-between","alignItems":"center","fontWeight":"600"}},[_c('v-icon',{attrs:{"width":"20","name":"arrow-left"}}),_vm._v(" Prev: ")],1),_c('CText',{attrs:{"display":{ base: 'none', md: 'block' },"textAlign":"right"}},[_vm._v(" "+_vm._s(_vm.prevLecture.title)+" ")])],1):_vm._e()],1),_c('CBox',{attrs:{"flex":"1"}},[(_vm.nextLecture)?_c('CBox',{attrs:{"p":"2","w":"90%","ml":"auto","border":".3px solid","borderRadius":"1","boxShadow":"sm","cursor":"pointer","fontSize":".75rem"},on:{"click":function($event){return _vm.handleNext(_vm.nextLecture)}}},[_c('CFlex',{attrs:{"justifyContent":"space-between","alignItems":"center","fontWeight":"600"}},[_vm._v(" Next: "),_c('v-icon',{attrs:{"width":"20","name":"arrow-right"}})],1),_c('CText',{attrs:{"display":{ base: 'none', md: 'block' }}},[_vm._v(" "+_vm._s(_vm.nextLecture.title)+" ")])],1):_vm._e()],1)],1)],1)],1),_c('CModalOverlay')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }