<template>
    <CBox pb="80px">
        <MediaPlayer
            v-if="activeLecture"
            :activeLecture="activeLecture"
            :prevLecture="prevLecture"
            :nextLecture="nextLecture"
            :onClose="removeActiveLecture"
            :isMinimized="isMinimized"
            :onMinimize="onMinimize"
            :setActiveLecture="setActiveLecture"
        />
        <!-- <AddLecture /> -->

        <CFlex minH="30vh" w="100%" alignItems="center">
            <CBox textAlign="center" w="756px" maxW="80%" mx="auto">
                <CHeading
                    fontWeight="600"
                    :fontSize="{
                        base: '1.6rem',
                        sm: '1.8rem',
                        md: '2.2rem',
                        lg: '2.6rem',
                    }"
                    lineHeight="1.4"
                    :color="mode('blackAlpha.800', 'gray.100')"
                >
                    Raise the next
                    <span
                        :style="{
                            color: '#E63946',
                        }"
                        >pious</span
                    >
                    generation on the firm understanding of Islaam
                </CHeading>
            </CBox>
        </CFlex>

        <CBox>
            <Container>
                <CBox
                    v-if="
                        filteredLectures.length > 1 &&
                        lastPlayedLecture &&
                        !isMinimized
                    "
                    pb="3"
                >
                    <CText :color="mode('inherit', 'gray.300')"
                        >Pick up from where you left</CText
                    >
                    <MediaItem
                        :lecture="lastPlayedLecture"
                        :activeLecture="activeLecture"
                        :setActiveLecture="setActiveLecture"
                        :removeActiveLecture="removeActiveLecture"
                    />
                </CBox>

                <!-- <CDivider borderColor="gray.400" /> -->

                <CBox>
                    <LectureFilter
                        :searchParam="searchParam"
                        :handleSearch="handleSearch"
                        :activeTag="activeTag"
                        :filterByTag="filterByTag"
                        :selectedLanguage="selectedLanguage"
                        :filterByLanguage="filterByLanguage"
                    />
                </CBox>
                <CBox
                    border="0.5px solid"
                    borderColor="gray.400"
                    px="3"
                    py="5"
                    minH="200px"
                >
                    <CBox h="200px" w="100%" v-if="isLoading">
                        <LoadingSpinner />
                    </CBox>
                    <CBox v-else-if="error" h="200px" w="100%">
                        <ErrorMsg :error="error" />
                    </CBox>

                    <CFlex
                        w="100%"
                        alignItems="center"
                        justifyContent="center"
                        v-else-if="filteredLectures?.length < 1"
                    >
                        <CText color="red" textAlign="center"
                            >No lectures available for selected filters.</CText
                        >
                    </CFlex>
                    <CBox v-else>
                        <Lectures
                            :limit="limit"
                            :handleLimit="handleLimit"
                            :activeLecture="activeLecture"
                            :filteredLectures="filteredLectures"
                            :lecturesLen="filteredLectures.length"
                            :setActiveLecture="setActiveLecture"
                            :removeActiveLecture="removeActiveLecture"
                        />
                    </CBox>
                </CBox>
            </Container>
        </CBox>
    </CBox>
</template>

<script>
import { db } from '@/config/firebase'
import { getDocs, collection, query, orderBy } from 'firebase/firestore'

import ButtonVue from '@/components/Button.vue'
import Header from '@/components/Header.vue'
import {
    mode,
    CBox,
    CFlex,
    CText,
    CButton,
    CInput,
    CDivider,
    CList,
    CListItem,
    CHeading,
} from '@chakra-ui/vue'
// import { data } from '@/helper/data'
import {
    languageOptions,
    categoryOptions,
    plyr_lastplayed,
} from '@/helper/constants'
import MediaPlayer from '@/features/MediaPlayer.vue'
import MinimizedPlayer from '@/features/MinimizedPlayer.vue'
import AddLecture from '@/features/AddLecture.vue'
import Lectures from '../features/Lectures.vue'
import LectureFilter from '@/features/LectureFilter.vue'

import MediaItem from '@/components/MediaItem.vue'
import TabMenu from '@/components/TabMenu.vue'
import SearchInput from '@/components/SearchInput.vue'
import { clientStorage, updateConfig } from '@/helper/functions'
import Container from '@/components/Container.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ErrorMsg from '@/components/ErrorMsg.vue'

// import AudioPlayerVue from "@/components/AudioPlayer.vue";

export default {
    name: 'HomePage',
    inject: ['config', 'timelogs', 'lastPlayedLecture', 'setLastPlayedLecture'],
    head: {
        title: {
            inner: 'BaytulMuslim | The best resources to learn about Nikaah (marriage), Child Upbringing and Marital Issues resolutions in Islaam',
        },
        // Meta tags
        meta: [
            { name: 'application-name', content: 'BaytulMuslim' },
            {
                name: 'description',
                content:
                    'BaytulMuslim | The best resources to learn about Nikaah (marriage), Child Upbringing and Marital Issues resolutions in Islaam',
                id: 'desc',
            }, // id to replace intead of create element
            // ...
            // Twitter
            { name: 'twitter:title', content: 'BaytulMuslim' },
            // with shorthand
            {
                n: 'twitter:description',
                c: 'BaytulMuslim | The best resources to learn about Nikaah (marriage), Child Upbringing and Marital Issues resolutions in Islaam',
            },
            // ...
            // Google+ / Schema.org
            { itemprop: 'name', content: 'BaytulMuslim: The Muslim Home' },
            {
                itemprop: 'description',
                content:
                    'The best resources to learn about Nikaah (marriage), Child Upbringing and Marital Issues resolutions in Islaam',
            },
            // ...
            // Facebook / Open Graph
            { property: 'fb:app_id', content: '123456789' },
            {
                property: 'og:title',
                content:
                    'The best resources to learn about Nikaah (marriage), Child Upbringing and Marital Issues resolutions in Islaam',
            },
            // with shorthand
            {
                p: 'og:image',
                c: 'https://user-images.githubusercontent.com/40659739/183295372-df858ea4-08f1-49e4-8ac2-c15c6479f0d9.png',
            },
            // ...
        ],
    },
    data() {
        return {
            mode,
            error: null,
            isLoading: true,
            limit: 10,
            isMinimized: false,
            lectures: [],
            activeTag: this.config?.tag || '',
            searchParam: '',
            activeLecture: null,
            activeWatchTime: 0,
            languageOptions,
            categoryOptions,
            selectedLanguage: this.config?.language || '',
        }
    },
    mounted() {
        this.fetchLectures()
    },
    methods: {
        onMinimize() {
            this.isMinimized = !this.isMinimized

            const el = document.getElementById('modal-portal-player')
            const firstChild = el.children[0].firstChild.firstChild
            firstChild.style.transition = 'margin-top .3s ease'
            if (this.isMinimized) {
                firstChild.style.marginTop = '100vh'
                document.body.style.overflow = 'scroll'

                setTimeout(() => {
                    el.style.display = 'none'
                }, 300)
            } else {
                el.style.display = 'block'
                document.body.style.overflow = 'hidden'

                setTimeout(() => {
                    firstChild.style.marginTop = '0'
                }, 100)
            }
        },
        setActiveLecture(audio, isNew = false) {
            // Go back to maximize view on playing a new lecture manually
            if (isNew) this.isMinimized = false

            this.activeWatchTime = 0
            this.activeLecture = null
            this.activeLecture = audio
            clientStorage.setItem(plyr_lastplayed, audio)
            this.setLastPlayedLecture(audio)
        },
        removeActiveLecture() {
            this.isMinimized = false
            this.setLastPlayedLecture(this.activeLecture)
            this.activeLecture = null
        },
        filterByTag(tag) {
            updateConfig('tag', tag)
            this.activeTag = tag
        },
        filterByLanguage(language) {
            updateConfig('language', language)
            this.selectedLanguage = language
        },
        handleSearch(value) {
            this.searchParam = value
        },
        handleLimit() {
            if (this.limit + 10 > this.filteredLectures.length) {
                this.limit = this.filteredLectures.length
            } else if (this.limit < this.filteredLectures.length) {
                this.limit += 10
            }
        },
        async fetchLectures() {
            try {
                const lecturesSnapshot = await getDocs(
                    query(collection(db, 'lectures'), orderBy('title'))
                )

                const allLectures = lecturesSnapshot.docs.map((doc) =>
                    doc.data()
                )

                this.lectures = allLectures
            } catch (error) {
                this.error = error.message
            } finally {
                this.isLoading = false
            }
        },
    },

    computed: {
        prevLecture() {
            if (!this.activeLecture) return null

            const indexOfActiveLecture = this.lectures.findIndex(
                (element) => element.id === this.activeLecture.id
            )

            if (indexOfActiveLecture === 0) return null
            return this.lectures[indexOfActiveLecture - 1]
        },
        nextLecture() {
            if (!this.activeLecture) return null

            const indexOfActiveLecture = this.lectures.findIndex(
                (element) => element.id === this.activeLecture.id
            )

            if (indexOfActiveLecture === this.lectures.length - 1) return null
            return this.lectures[indexOfActiveLecture + 1]
        },
        filteredLectures() {
            let clonedData = [...this.lectures],
                result = []

            if (clonedData.length < 1) return clonedData

            for (let i = 0; i < clonedData.length; i++) {
                const currLecture = clonedData[i]
                let isMatch = true

                if (this.activeTag && isMatch) {
                    isMatch = currLecture.tags.includes(this.activeTag)
                }

                if (this.searchParam && isMatch) {
                    isMatch =
                        currLecture.title
                            .toLowerCase()
                            .includes(this.searchParam.toLowerCase()) ||
                        currLecture.artist
                            .toLowerCase()
                            .includes(this.searchParam.toLowerCase())
                }
                if (this.selectedLanguage && isMatch) {
                    isMatch = currLecture.language === this.selectedLanguage
                }

                if (isMatch) {
                    result.push(currLecture)
                }
            }

            return result
        },
    },
    components: {
        CBox,
        CFlex,
        CText,
        CButton,
        CInput,
        CDivider,
        CList,
        CListItem,
        CHeading,
        Header,
        MediaPlayer,
        TabMenu,
        MediaItem,
        Button: ButtonVue,
        SearchInput,
        Container,
        MinimizedPlayer,
        AddLecture,
        Lectures,
        LectureFilter,
        LoadingSpinner,
        ErrorMsg,
    },
}
</script>
