<template>
    <CBox py="4" minH="65px" w="100%">
        <Container>
            <CFlex
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
            >
                <CBox :width="{ base: '120px', lg: '230px' }">
                    <router-link to="/">
                        <img :src="logo" />
                    </router-link>
                </CBox>

                <CBox
                    :display="{ base: 'none', md: 'block' }"
                    v-if="!isSignedIn"
                >
                    <AuthAlert />
                </CBox>

                <CFlex
                    :w="{ base: '100%', sm: 'initial' }"
                    alignItems="center"
                    :justifyContent="{ base: 'space-between', sm: 'center' }"
                >
                    <CBox
                        mr="2"
                        fontSize=".9rem"
                        :color="mode('inherit', 'gray.300')"
                    >
                        <router-link to="/about">About</router-link>
                    </CBox>
                    <CBox
                        mr="2"
                        fontSize=".9rem"
                        :color="mode('inherit', 'gray.300')"
                    >
                        <router-link to="/contact">Contact</router-link>
                    </CBox>
                    <CBox mr="2" @click="onColorModeChange">
                        <CButton borderRadius="full" px="2">
                            <v-icon
                                :name="colorMode === 'dark' ? 'sun' : 'moon'"
                                width="20"
                                :color="mode('green.600', '#fff')"
                            ></v-icon>
                        </CButton>
                    </CBox>

                    <CButton
                        v-if="!isSignedIn"
                        mr="2"
                        fontSize=".8rem"
                        fontWeight="500"
                        py="0px"
                        maxH="30px"
                        variantColor="blue"
                        @click="handleSignin"
                        display="flex"
                        gap="1"
                    >
                        Signin
                        <v-icon
                            ml="2"
                            width="15"
                            :color="mode('green.600', '#fff')"
                            name="log-in"
                        >
                        </v-icon>
                    </CButton>

                    <CBox v-else>
                        <CButton
                            mr="2"
                            fontSize=".8rem"
                            fontWeight="500"
                            py="0px"
                            maxH="30px"
                            variantColor="red"
                            @click="handleLogout"
                            display="flex"
                            gap="1"
                            px="2"
                            :isLoading="isLoggingOut"
                        >
                            Logout
                            <v-icon
                                ml="2"
                                width="15"
                                :color="mode('green.600', '#fff')"
                                name="log-out"
                            >
                            </v-icon>
                        </CButton>
                    </CBox>
                </CFlex>
            </CFlex>
            <CBox :display="{ base: 'block', md: 'none' }" v-if="!isSignedIn">
                <AuthAlert v-if="!isSignedIn" />
            </CBox>
        </Container>
    </CBox>
</template>

<script>
import {
    mode,
    CBox,
    CFlex,
    CList,
    CListItem,
    CButton,
    CStack,
    CAvatar,
    CMenu,
    CMenuButton,
    CMenuList,
    CMenuItem,
} from '@chakra-ui/vue'

import Logo from '../assets/logo.svg'
import LogoDark from '../assets/logo-dark.svg'
import { updateConfig } from '@/helper/functions'

import Container from './Container.vue'
import AuthAlert from './AuthAlert.vue'

export default {
    name: 'HeaderComponent',
    inject: ['$chakraColorMode', '$toggleColorMode', 'isLoggingOut'],
    props: {
        user: {
            type: Object,
        },
        isSignedIn: {
            type: Boolean,
        },
        handleSignin: {
            type: Function,
        },
        handleLogout: {
            type: Function,
        },
    },
    components: {
        CBox,
        CFlex,
        CList,
        CListItem,
        Container,
        CButton,
        CStack,
        CAvatar,
        CMenu,
        CMenuButton,
        CMenuList,
        CMenuItem,
        AuthAlert,
    },
    data() {
        return {
            mode,
            navLinks: ['Donate', 'About', 'Sign in'],
        }
    },
    computed: {
        logo() {
            return mode(Logo, LogoDark)
        },
        colorMode() {
            return this.$chakraColorMode()
        },
    },
    methods: {
        onColorModeChange() {
            this.$toggleColorMode()
            updateConfig('theme', this.$chakraColorMode())
        },
    },
}
</script>
